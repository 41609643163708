import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(public apiService: ApiService) { }
  init(): Observable<any> {
    let apiUrl = 'appSettings';
    return this.apiService.request('post', apiUrl, {});
  }

  loadDashboardTypes(): Observable<any> {
    let apiUrl = '/assets/data/subManagementTypes.data.json';
    return this.apiService.getJSONfromLocal(apiUrl);
  }

}
