<mat-toolbar *ngIf="loggedIn" class="app-navbar">
    <img class="logo" src="./assets/images/PurpleCube-Logo-R-Light.png">
    <ul class="nav-wrapper">
        <li *ngFor="let item of menuItems; let i = index">
            <button mat-button (click)="selectedIndex = i; onMenuItemClick(item)" [class.active]="selectedMenuItemIndex == i">
                {{item.title}}
            </button>
        </li>
    </ul>
    <span class="spacer"></span>
    <!--<button mat-icon-button>
        <mat-icon svgIcon="play-icon"></mat-icon>
    </button>-->
    <div matRipple [matRippleCentered]="true" class="help-btn">
      <a href="https://purplecube.freshdesk.com/support/home"><p>?</p></a>
    </div>
    <div matRipple [matRippleCentered]="true" [matMenuTriggerFor]="menu" class="profile-image"></div>
</mat-toolbar>
<div class="loading-indicator-wrapper" [class.showOnTop0]="!loggedIn">
    <mat-progress-bar mode="indeterminate" [class.hide]="!showLoadingIndicator"></mat-progress-bar>
</div>
<div class="page-container" [ngClass]="{'loggedIn':loggedIn}">
    <router-outlet></router-outlet>
</div>
<mat-menu #menu="matMenu">
    <button mat-menu-item routerLink="/profile-management">
        <!--<mat-icon>account_circle</mat-icon>-->
        <span>Profile</span>
    </button>
    <button mat-menu-item (click)="onLogoutClick()">
        <span>Logout</span>
    </button>
</mat-menu>
