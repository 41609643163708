import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import {OktaAuthGuard, OktaCallbackComponent} from '@okta/okta-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard-management',
    pathMatch: 'full'
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule)
  },
  // {
  //   path: 'login/callback',
  //   component: OktaCallbackComponent,
  // },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'thank-you-page',
    loadChildren: () => import('./pages/thank-you-page/thank-you-page.module').then(m => m.ThankYouPageModule)
  },
  {
    path: 'dashboard-management',
    // canActivate: [OktaAuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard-management/dashboard-management.module').then(m => m.DashboardManagementModule)
  },
  {
    path: 'data-management',
    // canActivate: [ OktaAuthGuard ],
    loadChildren: () => import('./pages/data-management/data-management.module').then(m => m.DataManagementModule)
  },
  {
    path: 'profile-management',
    // canActivate: [ OktaAuthGuard ],
    loadChildren: () => import('./pages/profile-management/profile-management.module').then(m => m.ProfileManagementModule)
  },

  {
    path: 'tutorials',
    // canActivate: [ OktaAuthGuard ],
    redirectTo: 'dashboard-management',
    pathMatch: 'full'
  },
  {
    path: 'contact-us',
    // canActivate: [ OktaAuthGuard ],
    redirectTo: 'dashboard-management',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      useHash: false
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
