import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularMaterialModule } from './modules/angular-material.modules';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from "./interceptors/auth.interceptor";
import {OktaAuthModule, OKTA_CONFIG} from "@okta/okta-angular";
import {Router} from "@angular/router";

const oktaConfig = {
  issuer: 'https://purplecubeai.okta.com/oauth2/default',
  clientId: '0oaq87qu7aK1z0grq416',
  scopes: ['openid', 'profile', 'email'],
  redirectUri: window.location.origin + '/login/callback'
};

const oktaConfigs = Object.assign({
    onAuthRequired: (oktaAuth, injector) => {
      const router = injector.get(Router);
      // Redirect the user to your custom login page
      router.navigate(['/login']);
    }
  }, oktaConfig);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule,
    OktaAuthModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
  },
    { provide: OKTA_CONFIG, useValue: oktaConfigs }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
