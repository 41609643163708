import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import { Constants } from "../shared/common.constants";


@Injectable({
  providedIn: 'root'
})
export class DataService {
  user = JSON.parse(localStorage.getItem(Constants.localStorageKeys.KEY_LOGGEDIN_USER));

   stepObject = {
    user: '',
    team: ''
  };
  public setupWizardId = {
    verticals: '',
    users_cases: [],
  };
  private setupWizard = new BehaviorSubject<any>(this.stepObject);
  public currentStep = this.setupWizard.asObservable();


  constructor(
    private http: HttpClient,

  ){}
  private appSetting: any;

  private dashboardTypes: any[] = [];
  private innerSteps: any[] = [];
  private allOptions: any[] = [];
  private currentDBObject: any;
  private allDeshboards: any[] = [];



  setAppData(data) {
    this.appSetting = data;
  }
  getAppData() {
    return this.appSetting;
  }

  setDashboardTypes(data) {
    this.dashboardTypes = data;
  }

  getDashboardTypes() {
    return this.dashboardTypes;
  }

  setInnerSteps(data) {
    this.innerSteps = data;
  }
  getInnerSteps() {
    return this.innerSteps;
  }

  setAllOptions(data) {
    this.allOptions = data;
  }
  getAllOptions() {
    return this.allOptions;
  }

  setCurrentDBObject(obj) {
    this.currentDBObject = obj;
  }

  getCurrentDBObject() {
    return this.currentDBObject;
  }

  addNewConnection(obj) {
    this.allDeshboards.push(obj)
  }

  getAllAIDeshboards() {
    return this.allDeshboards;
  }

  getDashboards() {
    return this.http.get(environment.apiBaseUrl + '/dashboard-reuests');
  }

  setupWizardStep(obj) {
    this.setupWizard.next({...this.setupWizard.getValue(), ...obj});
    console.log(obj)
  }

  createDashboardRequest(obj) {
    return this.http.post(environment.apiBaseUrl + '/dashboard-reuests', obj)
  }

  updateUser(id: number, payload) {
    return this.http.put(environment.apiBaseUrl + '/users/' + id, payload)
  }

  getUserData(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + '/users/me');
  }

  resetPassword(id:number, payload): Observable<any> {
    return this.http.put(environment.apiBaseUrl + '/users/' + id, payload)
  }
  getAiDashboards() {
    return this.http.get(environment.apiBaseUrl + '/a-i-dasboards')
  }

  getTeam() {
    return this.http.get(environment.apiBaseUrl + '/teams')
  }

  getSubscription() {
    return this.http.get(environment.apiBaseUrl + '/subscriptions')
  }

  getVertical() {
    return this.http.get(environment.apiBaseUrl + '/verticals')
  }

  getDashboard() {
    return this.http.get(environment.apiBaseUrl + '/dashboard-types')
  }

  getUserCase() {
    return this.http.get(environment.apiBaseUrl + '/user-cases')
  }

  getInvoices() {
    return this.http.get(environment.apiBaseUrl + '/invoices')
  }

  getUsers() {
    return this.http.get(environment.apiBaseUrl + '/users')
  }

  userUpdateById(user, value) {
    return this.http.put(environment.apiBaseUrl + '/users/' + user.id, {...value})
  }

  userDeleteById(user) {
    return this.http.delete(environment.apiBaseUrl + '/users/' + user.id)
  }

  userDeleteFromOrganisation(user) {
    return this.http.post(environment.apiBaseUrl + '/team/remove-user-diyotta', user);
  }

  getSubcriptions(id: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + '/subscriptions/' + id);
  }

  getUseCase(id: number): Observable<any> {
    return this.http.get(environment.apiBaseUrl + '/user-cases/' + id);
  }

  sendInvite(obj) {
    return this.http.post(environment.apiBaseUrl + '/team/send-invite', obj);
  }
}
