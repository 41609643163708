import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, RoutesRecognized } from '@angular/router'
import {DomSanitizer, Meta, Title} from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { Constants } from './shared/common.constants';
import { AppEventService } from './services/appEvent.service';
import { AppService } from './services/app.service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  showLoadingIndicator: boolean = false;
  loggedIn: boolean = true;
  private subscriptionDestroyed$: Subject<void> = new Subject<void>();
  selectedMenuItemIndex: number = 0;
  title = 'PurpleCube | An End-to-End AaaS Platform';

  menuItems = [{
    title: 'Dashboard Management',
    url: '/dashboard-management'
  }, {
    title: 'Data Management',
    url: '/data-management'
  }, {
    title: 'Tutorials',
    redirectUrl: 'https://purplecube.ai/tutorials'
  }, {
    title: 'Contact',
    redirectUrl: 'https://purplecube.ai/user-contact'
  }];

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private appEventService: AppEventService,
    private appService: AppService,
    private dataService: DataService,
    private titleService: Title,
    private metaService: Meta
  ) {
    iconRegistry.addSvgIcon(
      'play-icon',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/play-icon.svg')
    );

    this.appEventService.loggedInOutEmitter.pipe(takeUntil(this.subscriptionDestroyed$)).subscribe((data) => {
      if (data['loggedIn'] === true) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
        //clear localstorage and data-service
        window.localStorage.clear();
      }
    });
    this.appEventService.showLoadingEmitter.pipe(takeUntil(this.subscriptionDestroyed$)).subscribe((showLoading) => {
      /*setTimeout(() => {
        this.showLoadingIndicator = showLoading;
      }, 0);*/
      this.showLoadingIndicator = showLoading;
    });

    this.appService.loadDashboardTypes().subscribe(data => this.dataService.setDashboardTypes(data));
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'description', content: 'PurpleCube is a Customer-Centric & End-to-End Analytics as' +
          ' a Service platform that combines Cloud Datawarehousing, Data Integration, BI and AI Analytics all in one solution.'},
    ]);
    /*if(window.location.href.indexOf('/sign-in') !== -1) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
    console.log('AppComponent => ngOnInit')*/
  }

  ngAfterViewInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.subscriptionDestroyed$)).subscribe((path: RoutesRecognized) => {
      //console.log(path);
      if (path && path.urlAfterRedirects) {
        const url = path.urlAfterRedirects;
        const activeModule_url = url.split('/')[1];
        if (activeModule_url !== 'sign-in' && activeModule_url !== 'sign-up'
          && activeModule_url !== 'thank-you-page' && activeModule_url !== 'reset-password') {
          this.loggedIn = true;
          this.selectedMenuItemIndex = this.menuItems.findIndex((item) => {
            if(item.url) {
              const pageUrlArr = item.url.split('/');
              return pageUrlArr[1].toLowerCase() === activeModule_url.toLowerCase()
            }
          });
          //console.log('selectedMenuItemIndex: ', this.selectedMenuItemIndex)
        } else {
          this.loggedIn = false;
        }
      }
    });
  }

  onMenuItemClick(menuItem) {
    if(menuItem.url) {
      this.router.navigateByUrl(menuItem.url);
    } else {
      window.open(menuItem.redirectUrl, '_blank');
    }
  }

  onLogoutClick() {
    if(window.localStorage.getItem(Constants.localStorageKeys.KEY_LOGGEDIN_USER)) {
      window.localStorage.removeItem(Constants.localStorageKeys.KEY_LOGGEDIN_USER);
    }
    this.appEventService.loggedInOutEmitter.next({ loggedIn: false });
    this.router.navigateByUrl('/sign-in');
  }

  ngOnDestroy() {
    this.subscriptionDestroyed$.next();
    this.subscriptionDestroyed$.complete();
  }
}
