import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs'; // For rxjs 6

interface LoggedInOutObj {
    loggedIn: boolean;
}
interface notificationData {
    key: string;
    params: any;
}

@Injectable({
    providedIn: 'root'
})
export class AppEventService {
    public showLoadingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    public loggedInOutEmitter: EventEmitter<LoggedInOutObj> = new EventEmitter<LoggedInOutObj>();
    public refresPagehEmitter: EventEmitter<notificationData> = new EventEmitter<notificationData>();
    private selectedLanguage = new Subject<string>();

    constructor() { }

    public getLanguageObservable(): Observable<string> {
        return this.selectedLanguage.asObservable();
    }

    public setLanguageData(data) {
        this.selectedLanguage.next(data);
    }
}