import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Constants } from '../shared/common.constants';
import { ApiService } from "./api.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInterface } from "../models/user.interface";

const headers =  new HttpHeaders()
  .set('Authorization',  `SSWS 00i8uLz8weG8Bss5xcknyhFHjhzb5bF_dYrmsRrCWk`);


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<UserInterface>;
  public  user: Observable<UserInterface>;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private http: HttpClient,

  ) {
    this.userSubject = new BehaviorSubject<UserInterface>(JSON.parse(localStorage.getItem(Constants.localStorageKeys.KEY_LOGGEDIN_USER)));
    this.user = this.userSubject.asObservable();
  }

  isAuthenticated() {
    let loggedInUser = JSON.parse(window.localStorage.getItem(Constants.localStorageKeys.KEY_LOGGEDIN_USER));
    if (loggedInUser) {
      return true;
    } else {
      this.router.navigateByUrl('/sign-in');
      return false;
    }
  }

  signIn(payload) {
    return this.http.post(environment.apiBaseUrl + '/auth/local', payload);
  }

  oktaSignIn(payload) {
    return this.http.post('https://purplecubeai.okta.com/api/v1/authn', payload);
  }

  signUp(payload) {
    console.log(payload);
    return this.http.post(environment.apiBaseUrl + '/auth/local/register', payload);
  }

  oktaSignUp(payload) {
    return this.http.post('https://purplecubeai.okta.com/api/v1/users?activate=false', payload, {headers: headers});
  }

  forgotPassword(payload) {
    return this.http.post(environment.apiBaseUrl + '/auth/forgot-password', payload);
  }

  resetPassword(payload) {
    return this.http.post(environment.apiBaseUrl + '/auth/reset-password', payload);
  }
  public get userValue(): UserInterface {
    return this.userSubject.value;
  }
}
