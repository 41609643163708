import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public constants: any;
  constructor() {
    let environment = 'staging'; // local, staging or production
    if (environment === 'local') {
      this.constants = {
        API_ENDPOINT: 'http://localhost.com:8089/'
      }
    } else if (environment === 'staging') {
      this.constants = {
        API_ENDPOINT: 'https://run.mocky.io/v3/',
        //API_ENDPOINT: 'https://staging.purplecube.com:8090/'
      }
    } else {
      this.constants = {
        API_ENDPOINT: 'https://purplecube.com:8090/'
      }
    }
  }

  // method to get api end point
  public getAPIEndPoint() {
    return this.constants.API_ENDPOINT;
  }

  get deFaultLanguage() {
    if(environment.production) {
      return 'en';
    } else {
      return 'fr'
    }
  }
}
