import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from "../../environments/environment";

import { ConfigService } from './config.service';

// Api service will call all APIs of the apps. All the API request should call this API.
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private _configService: ConfigService) {}

  request(method, url, data) {
    let endPoint = this._configService.getAPIEndPoint();
    // let apiURL = endPoint + url;
    // let apiURL = 'http://localhost.com:1337/' + url;
    let apiURL = environment.apiBaseUrl + url;

    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
      withCredentials: false
    };
    let body = JSON.stringify(data);

    let request;
    if (method === 'post') {
      request = this.http.post(apiURL, body, options).pipe(map((res: any) => {
        //return this.extractData(res);
        return res;
      }),
      catchError((error: any) => {
        console.log(error);
        return this.handleError(error);
      }));
    } else if (method === 'get') {
      let httpParams = this.toHttpParams(data);
      request = this.http.get(apiURL, { params: httpParams }).pipe(map((res: any) => {
        //return this.extractData(res);
        return res;
      }),
      catchError((error: any) => {
        console.log(error);
        return this.handleError(error);
      }));
    } else if (method === 'put') {
      request = this.http.put(apiURL, body, options).pipe(map((res: any) => {
        //return this.extractData(res);
        return res;
      }),
      catchError((error: any) => {
        console.log(error);
        return this.handleError(error);
      }));
    } else if (method === 'delete') {
      let options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
        withCredentials: false,
        body: body
      };
      request = this.http.delete(apiURL, options).pipe(map((res: any) => {
        //return this.extractData(res);
        return res;
      }),
      catchError((error: any) => {
        console.log(error);
        return this.handleError(error);
      }));
    }

    return request;
  }

  // search params data
  private toHttpParams(obj: Object): HttpParams {
    let params = new HttpParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const val = obj[key];
        if (val !== null && val !== undefined) {
          params = params.append(key, val.toString());
        }
      }
    }
    return params;
  }

  // get response API
  private extractData(res) {
    // invalid response
    if (res.status < 200 || res.status >= 300) {
      throw new Error('Bad response status: ' + res.status);
    }
    let response = res._body.replace(/\)]}'/g, '');
    let data = JSON.parse(response);
    return data || {};
  }

  // if api fails then handel all server side errors
  private handleError(error: any) {
    error.message = error.message || 'Server error!';
    return throwError(error);
  }

  getJSONfromLocal(_jsonURL): Observable<any> {
    return this.http.get(_jsonURL);
  }

}
